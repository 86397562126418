import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import QnACreateQuestion from './QnACreateQuestion';
import QnAList from './QnAList';
import QnAChat from './QnAChat';
import { useUser } from '../../context/UserContext'; // Import the useUser hook
import './QnA.css';

function QnA() {
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [showCreateQuestion, setShowCreateQuestion] = useState(false);

    const { user } = useUser(); // Get the user from UserContext

    const handleSelectQuestion = (question) => {
        setSelectedQuestion(question);
        setShowCreateQuestion(false);
    };

    const handleBack = () => {
        setSelectedQuestion(null);
        setShowCreateQuestion(false);
    };

    const toggleCreateQuestionForm = () => {
        setShowCreateQuestion(!showCreateQuestion);
        setSelectedQuestion(null);
    };

    const handleQuestionCreated = () => {
        setShowCreateQuestion(false);
        setSelectedQuestion(null);
    };

    return (
        <div className="full-bg">
            <Container className="qna-container mt-3">
                <Row className="justify-content-between align-items-center mb-3">
                    <Col>
                        <h1 className="qna-title">Vprašanja</h1>
                    </Col>
                    <Col className="text-end">
                        {!selectedQuestion && (
                            <Button variant={showCreateQuestion ? "secondary" : "danger"} onClick={toggleCreateQuestionForm}>
                                {showCreateQuestion ? 'Nazaj na vprašanja' : 'Novo vprašanje'}
                            </Button>
                        )}
                        {selectedQuestion && (
                            <Button variant="secondary" onClick={handleBack}>Nazaj na vprašanja</Button>
                        )}
                    </Col>
                </Row>
                {!selectedQuestion ? (
                    <>
                        {showCreateQuestion ? (
                            <QnACreateQuestion onQuestionCreated={handleQuestionCreated} />
                        ) : (
                            <QnAList onSelectQuestion={handleSelectQuestion} isAdmin={user && user.role === "admin"} />
                        )}
                    </>
                ) : (
                    <QnAChat questionId={selectedQuestion.id} questionTitle={selectedQuestion.title} />
                )}
            </Container>
        </div>
    );
}

export default QnA;
