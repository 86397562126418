import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase-config';
import { collection, addDoc, doc, updateDoc, getDocs } from 'firebase/firestore';
import { Button, Form, Container, Row, Col, Alert } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './NoticeCreate.css';
import { useUser } from '../../context/UserContext';

const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
    ['link'],
    ['clean'],
  ],
};

function NoticeCreate({ onNoticeCreated, noticeToEdit }) { // Accept noticeToEdit prop
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [unit, setUnit] = useState('general');
  const [unitName, setUnitName] = useState('Splošno');
  const [units, setUnits] = useState([]);
  const [error, setError] = useState('');

  const { user } = useUser();

  useEffect(() => {
    if (noticeToEdit) {
      setTitle(noticeToEdit.title);
      setText(noticeToEdit.text);
      setUnit(noticeToEdit.unit);
      setUnitName(noticeToEdit.unitName);
    }
  }, [noticeToEdit]);

  useEffect(() => {
    const fetchUnits = async () => {
      const unitsCollectionRef = collection(db, 'units');
      const unitsSnapshot = await getDocs(unitsCollectionRef);
      const unitsList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUnits(unitsList);
    };

    fetchUnits();
  }, []);

  const handleUnitChange = (e) => {
    const selectedUnitId = e.target.value;
    const selectedUnit = units.find(unit => unit.id === selectedUnitId);
    setUnit(selectedUnitId);
    setUnitName(selectedUnit ? selectedUnit.unitName : 'Splošno');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setError('You must be logged in to create a notice.');
      return;
    }
    if (!title.trim() || !text.trim()) {
      setError('Title and text cannot be empty.');
      return;
    }

    // Construct the notice object, ensuring we only set createdAt and displayName on creation
    const newNotice = {
      title,
      text,
      unit,
      unitName,
    };

    // Only set these fields if it's a new notice
    if (!noticeToEdit) {
      newNotice.createdAt = new Date();
      newNotice.uid = user.uid;
      newNotice.displayName = user.displayName || 'Anonymous';
    }

    try {
      if (noticeToEdit) {
        await updateDoc(doc(db, 'notices', noticeToEdit.id), newNotice); // Update existing notice
      } else {
        await addDoc(collection(db, 'notices'), newNotice); // Add new notice
      }
      setTitle('');
      setText('');
      setUnit('general');
      setUnitName('Splošno');
      setError('');
      onNoticeCreated();
    } catch (error) {
      setError('Failed to create or update notice. Please try again.');
      console.error('Error creating or updating notice:', error);
    }
  };

  return (
    <Container className="notice-create-container">
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Naslov</Form.Label>
              <Form.Control
                type="text"
                placeholder="Vpišite naslov"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Besedilo</Form.Label>
              <div className="quill-container">
                <ReactQuill
                  value={text}
                  onChange={setText}
                  placeholder="Vpišite obvestilo"
                  theme="snow"
                  modules={modules}
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Enota</Form.Label>
              <Form.Control
                as="select"
                value={unit}
                onChange={handleUnitChange}
              >
                <option value="general">Splošno</option>
                {units
                  .filter(unit => user.role === 'admin' || unit.id === user.unit)
                  .map(unit => (
                    <option key={unit.id} value={unit.id}>{unit.unitName}</option>
                  ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Button variant="danger" type="submit">
          {noticeToEdit ? 'Posodobi obvestilo' : 'Objavi obvestilo'}
        </Button>
      </Form>
    </Container>
  );
}

export default NoticeCreate;
