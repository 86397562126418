import React, { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../../firebase/firebase-config';


function BenefitDetail({ benefit }) {

    useEffect(() => {
        // Mark as read when the component is mounted
        const markAsRead = async () => {
          const userDocRef = doc(db, 'users', auth.currentUser.uid);
          await updateDoc(userDocRef, {
            [`lastRead.benefits.${benefit.id}`]: serverTimestamp()
          });
        };
    
        markAsRead();
      }, [benefit.id]);

    return (
        <Card className="d-flex flex-column notice-detail-card">
            <Card.Header as="h5" className="notice-detail-card-header">{benefit.title}</Card.Header>
            <Card.Body className="flex-grow-1 notice-detail-card-body">
                {/* Render the saved rich text as HTML */}
                <div dangerouslySetInnerHTML={{ __html: benefit.text }} />
            </Card.Body>
        </Card>
    );
}

export default BenefitDetail;
