import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase-config';
import { collection, addDoc, doc, updateDoc, getDocs } from 'firebase/firestore';
import { Button, Form, Container, Row, Col, Alert } from 'react-bootstrap';
import { useUser } from '../../context/UserContext';
import Contact from '../../models/Contact'; // Import the Contact model

function ContactCreate({ onContactCreated, contactToEdit }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [unit, setUnit] = useState('general');
  const [unitName, setUnitName] = useState('Splošno');
  const [units, setUnits] = useState([]);
  const [error, setError] = useState('');

  const { user } = useUser();

  useEffect(() => {
    if (contactToEdit) {
      setName(contactToEdit.name);
      setEmail(contactToEdit.email);
      setPhone(contactToEdit.phone);
      setUnit(contactToEdit.unit);
      setUnitName(contactToEdit.unitName);
    }
  }, [contactToEdit]);

  useEffect(() => {
    const fetchUnits = async () => {
      const unitsCollectionRef = collection(db, 'units');
      const unitsSnapshot = await getDocs(unitsCollectionRef);
      const unitsList = unitsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setUnits(unitsList);
    };

    fetchUnits();
  }, []);

  const handleUnitChange = (e) => {
    const selectedUnitId = e.target.value;
    const selectedUnit = units.find((unit) => unit.id === selectedUnitId);
    setUnit(selectedUnitId);
    setUnitName(selectedUnit ? selectedUnit.unitName : 'Splošno');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user) {
      setError('You must be logged in to create a contact.');
      return;
    }
    if (!name.trim() || !email.trim() || !phone.trim()) {
      setError('Name, email, and phone cannot be empty.');
      return;
    }

    const contactData = new Contact({
      name,
      email,
      phone,
      unit,
      unitName,
      uid: user.uid,
    });

    try {
      if (contactToEdit) {
        await updateDoc(doc(db, 'contacts', contactToEdit.id), contactData.toFirestore());
      } else {
        await addDoc(collection(db, 'contacts'), contactData.toFirestore());
      }

      setName('');
      setEmail('');
      setPhone('');
      setUnit('general');
      setUnitName('Splošno');
      setError('');
      onContactCreated();
    } catch (error) {
      setError('Failed to create or update contact. Please try again.');
      console.error('Error creating or updating contact:', error);
    }
  };

  return (
    <Container className="contact-create-container">
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter contact name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter contact email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="tel"
                placeholder="Enter contact phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Group>
              <Form.Label>Unit</Form.Label>
              <Form.Control as="select" value={unit} onChange={handleUnitChange}>
                <option value="general">Splošno</option>
                {units.map((unit) => (
                  <option key={unit.id} value={unit.id}>
                    {unit.unitName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Button variant="danger" type="submit">
          {contactToEdit ? 'Update Contact' : 'Create Contact'}
        </Button>
      </Form>
    </Container>
  );
}

export default ContactCreate;
